@import "../../styles/core/core-variables";

.edit-affiliations-actions {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%
}

.custom-table {
  table {
    width: 100%;
    border-collapse: collapse;

    thead {
      background-color: $primary-color;
      th {
        text-align: left;
        color: $white-color;
        padding: $padding-4;
        font-size: $lg-font;
        border-right: 1px solid rgba(224, 241, 255, 0.1);
      }
    }

    tbody {
      border: 1px solid $grey-shade-10;
      border-top: none;


      tr:nth-child(odd) {
        background-color: #F7F7F7;
      }

      tr:nth-child(even) {
        background-color: #D9D9D9;
      }

      tr {
        &.dark {
          background-color: #76B9F433;
          font-weight: bold;
        }
        &.row-collapsed {
          display: none;
        }
        &.collapse-all {
          display: none;
        }
      }

      td {
        padding-left: $padding-4;
        padding-right: $padding-4;
        height: 80px;
      }
    }
  }
  .expand-collapse-wrapper {
    background: white;
    color: black;
    padding-left: 4px;
    height: 23px;
    display: flex;
    align-items: center;
  }
  .expand-collapse-icon-wrapper {
    i {
      background: $primary-color;
      padding: 5px;
      display: flex;
      justify-content: center;
      align-items: normal;
      width: 20px;
      height: 20px;
      color: #fff;
      border-radius: 50%;
    }
  }
}

.edit-topic-checkbox {
  &.reactCustomCheckbox .checkmark {
    top: 0.4rem;
    left: 0;
  }
  &.is-disabled {
    opacity: 0.5;
    cursor: auto;
  }
}

.edit-topic-dropdown {
  width: 150px;
  &.is-disabled {
    opacity: 0.5;
  }
}

.white-outline-button {
  &.customButton.ui.button.primary-button {
    border-color: $white-color;

    &.no-button-background {
      color: $white-color
    }
  }

  &.customButton.ui.button.primary-button.no-button-background:hover {
    background-color: inherit;
    text-decoration: none;
  }
}